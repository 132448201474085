












































import Draggable from 'vuedraggable';
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
import VariableColumnModel from '@/models/configurator/variables/variable-column.model';

@Component({
  components: {
    Draggable,
  },
})
export default class DraggableList extends Vue {
  @VModel() selectedList!: VariableColumnModel[];

  @Prop() options!: VariableColumnModel[];
}
